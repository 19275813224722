import React from 'react'
import {graphql} from 'gatsby'
import get from 'lodash/get'
import {Helmet} from 'react-helmet'
import Layout from '../components/layout'
import EverPostPreview from "../components/everpost-preview";
import RichText from "../components/rich-text";

const Title = ({title}) => <h1 className="section-headline">{title}</h1>

class EverblogIndex extends React.Component {
	render() {
		const siteTitle = get(this, 'props.data.site.siteMetadata.title')
		const page = get(this.props, 'data.page')
		const posts = get(this, 'props.data.posts.edges')

		const title = `${page.title} | ${siteTitle}`;
		return (
			<Layout location={this.props.location} title={title}>
				<div className="mainArticle">
					<div className="wrapper">
						<Title title={page.title}/>
						<RichText content={page.content}/>
						<h2 className="section-headline">Featured articles</h2>
						<ul className="article-list">
							{posts.map(({node}) => <EverPostPreview post={node}/>)}
						</ul>
					</div>
				</div>
			</Layout>
		)
	}
}

export default EverblogIndex

export const pageQuery = graphql`
query EverblogIndexQuery {
  page: contentfulEverpost(tags: {elemMatch: {name: {eq: "page", in: "featured"}}}) {
    id: contentful_id
    title
    createdAt: created(formatString: "MMMM Do, YYYY")
    content {
      raw
      references {
				... on ContentfulEverpost {
					# __typename is required to resolve the references
				  __typename
					# contentful_id is required to resolve the references
					contentful_id
				}
				... on ContentfulAsset {
					# __typename is required to resolve the references
				  __typename
					# contentful_id is required to resolve the references
					contentful_id
					file {
            url
          }
				}
			}
    }
  }
  posts: allContentfulEverpost(sort: {fields: [created], order: DESC}, filter: {tags: {elemMatch: {name: {eq: "published", ne: "page", in: "featured"}}}}) {
    edges {
      node {
        title
        id: contentful_id
        createdAt: created(formatString: "MMMM Do, YYYY")
        tags {
          id: contentful_id
          name
        }
      }
    }
  }
  site {
    siteMetadata {
      title
    }
  }
}
`
